import React from "react"

const Title = ({ title, sectionNumber, subtitle, className }) => {
    return (
        <div className={className}>
            <div className="title-section">
                {sectionNumber != null && 
                    <div className="title-section-number-col">{"0" + sectionNumber + "."}</div>
                }
                <h1 className="title">{title}</h1>
            </div>
            <h2 className="subtitle">{subtitle}</h2>
        </div>
    )
}

export default Title