import React, {useState} from "react"
import Layout from "../components/Layout"
import {graphql, useStaticQuery} from "gatsby"
import PageSection from "../components/PageSection";
import Title from "../components/Title";
import styles from "../css/allblogs.module.css";
import BlogItem from "../components/singles/BlogItem";
import SEO from "../components/SEO"

const Blog = ({location}) => {
    const data = useStaticQuery(pageQuery);
    const allPosts = data.blog.edges;
    const emptyQuery = "";
    const [state, setState] = useState({
        filteredData: [],
        query: emptyQuery,
    });

    const handleInputChange = event => {
        console.log(event.target.value);
        const query = event.target.value;
        const posts = data.blog.edges || [];
        const filteredData = posts.filter(post => {
            const {title, tags} = post.node.frontmatter;
            return (
                title.toLowerCase().includes(query.toLowerCase()) ||
                (tags &&
                    tags
                        .join("")
                        .toLowerCase()
                        .includes(query.toLowerCase()))
            )
        });
        setState({
            query,
            filteredData,
        })
    };
    const {filteredData, query} = state;
    const hasSearchResults = filteredData && query !== emptyQuery;
    const posts = hasSearchResults ? filteredData : allPosts;

    return (
        <Layout>
            <SEO title="Blog" pathname={location.pathname}/>
            <PageSection>
                {data.page.edges.map(({node}) => {
                    return (
                        <div>
                            <Title title={node.frontmatter.heading} subtitle={node.frontmatter.subHeading}/>
                            <input
                                className={styles.searchInput}
                                type="text"
                                aria-label="Search"
                                placeholder="Type to filter posts..."
                                onChange={handleInputChange}
                            />
                            <div className={styles.blogs}>
                                {posts.sort((a, b) => (a.node.frontmatter.date < b.node.frontmatter.date) ? 1 : -1).map((blogItem) => {
                                    return (
                                        <BlogItem blogItem={blogItem.node.frontmatter}/>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </PageSection>
        </Layout>
    )
};

export const pageQuery = graphql`
    query {
        page: allMarkdownRemark(filter: {fileAbsolutePath: {regex : "\\/sections/blog/"} }) {
            edges {
                node {
                    id
                    frontmatter {
                        heading
                        subHeading
                        ctaText
                    }
                }
            }
        }
        blog: allMarkdownRemark(limit: 1000 filter: {frontmatter: {templateKey: {eq: "blog-template"}}}) {
            edges {
                node {
                    frontmatter {
                        slug
                        title
                        url
                        image
                        date
                        readingTime
                        tags
                    }
                    frontmatter {
                        templateKey
                    }
                    html
                }
            }
        }
    }
`;

export default Blog