import React from "react"
import styles from "../../css/blogItem.module.css"
import PropTypes from "prop-types"
import {Link} from "gatsby";

const BlogItem = ({blogItem}) => {

    const {slug, title, date, readingTime, image, url, tags} = blogItem;

    return (
        <article className={styles.blog}>
            <Link to={(url === null || url === "" || url === '""' || url ==='' ) ? `/blog/${slug}` : url}>
                <div className={styles.imgContainer}>
                    <img src={image} className={styles.thumbnail} alt={title}/>
                </div>
                <div className={styles.blogCard}>
                    <h4>{title}</h4>
                    <div className={styles.blogFooter}>
                        <p>{tags[0]}</p>
                        <p>{new Date(date).toLocaleString("en-US", {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })} - {readingTime} min</p>
                    </div>
                </div>
            </Link>
        </article>
    )
};

BlogItem.propTypes = {
    blogItem: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        body: PropTypes.string,
        date: PropTypes.string.isRequired,
        image: PropTypes.object.isRequired,
        url: PropTypes.string,
        readingTime: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
    }),
};

export default BlogItem